import { ReactNode, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { useRouter } from 'next/router'

import { unOrphan } from '@/utils/orphans'
import { slugify } from '@/utils/common'
import scrollToAnchor from '@/utils/scrollToAnchor'

import {
  Container,
  Content,
  ContentInner,
  Head,
  IconWrapper,
  Icon,
  Text
} from './Accordion.styled'

interface Props {
  question: string
  idx: number
  isOpen: boolean
  wrapWithH2?: boolean
  children: ReactNode
  onClick(open: number): void
}

function AccordionItem(props: Props) {
  const { idx, isOpen, question, wrapWithH2 = false, children, onClick } = props
  const ref = useRef<HTMLDivElement>(null)
  const refContainer = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const [height, setHeight] = useState<number | undefined>(
    isOpen ? undefined : 0
  )

  useEffect(() => {
    if (router.isReady) {
      const hashId = router.asPath.split('#')

      if (hashId.length > 1) {
        if (hashId[hashId.length - 1] === slugify(question)) {
          onClick(idx)
          setTimeout(() => {
            scrollToAnchor(slugify(question))
          }, 200)
        }
      }
    }
  }, [router.isReady])

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height)
    else setHeight(0)
  }, [isOpen])

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined
    const resizeObserver = new ResizeObserver(el => {
      setHeight(el[0].contentRect.height)
    })
    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, isOpen])

  return (
    <Container id={slugify(question)} ref={refContainer}>
      <Head type="button" onClick={() => onClick(idx)}>
        {wrapWithH2 ? (
          <h2>
            <Text as="strong">{parse(unOrphan(question))}</Text>
          </h2>
        ) : (
          <Text as="strong">{parse(unOrphan(question))}</Text>
        )}
        <IconWrapper>
          <Icon open={isOpen} />
        </IconWrapper>
      </Head>
      <Content style={{ height }}>
        <ContentInner ref={ref}>{children}</ContentInner>
      </Content>
    </Container>
  )
}

export default AccordionItem
