import { css } from '@emotion/react'

import type { Url } from '@/utils/i18n/types'

import Button from '@/components/common/Button'
import ButtonScroll from '@/components/common/ButtonScroll'

import { mediaColumns } from '@/styles/grid'
import { mediaUp } from '@/styles/media'
import { colors } from '@/styles/theme'
import { LegiaMedium, Text1 } from '@/styles/typography'

interface Props {
  text: string
  button: string
  link?: Url
  scrollButton?: string
  dark?: boolean
  dataTestId?: string
}
function TextWithButton(props: Props) {
  const { text, button, link, scrollButton, dark = false, dataTestId } = props
  return (
    <div css={buttons}>
      <Text1 css={[dark && textDark, buttonText]}>{text}</Text1>
      <div css={buttonWrapper}>
        {scrollButton ? (
          <ButtonScroll
            anchor={scrollButton}
            text={button}
            {...(dark && { style: 'green' })}
          />
        ) : (
          <Button text={button} link={link} dataTestId={dataTestId} />
        )}
      </div>
    </div>
  )
}

const buttons = css`
  ${mediaColumns({ xs: [8, 2] })};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  ${mediaUp('md')} {
    flex-direction: row;
  }
`

const buttonWrapper = css`
  margin-top: 18px;

  ${mediaUp('md')} {
    margin-top: 0;
    margin-inline-start: 40px;
  }
`

const textDark = css`
  color: ${colors.white};
  text-align: center;
`

const buttonText = css`
  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }
`

export default TextWithButton
