import 'swiper/css'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'
import { useEffect } from 'react'

import { css } from '@emotion/react'

import { useRouter } from 'next/router'

import { FaqItem } from '@/service'
import { Grid } from '@/styles/grid'
import Accordion from '@/components/common/Accordion'
import TextWithButton from '@/components/common/TextWithButton'

import { mediaColumns } from '@/styles/grid'
import { mediaUp } from '@/styles/media'
import { spacingSection, spacingSmallSection } from '@/styles/mixins'
import Title from '@/components/common/Title'

import { useFetchFaqData } from '@/service/apiHooks/useFetchFaqData'

import { ApiPaths } from '@/utils/apiPaths'

import FaqPriceList from './FaqPriceList'

interface Props {
  category?: string
  slug?: string
  slugEn?: string
  faqPage?: boolean
  withoutMargin?: boolean
  dataTestId?: string
  categoryUrlSlug?: string
  forHomePage?: boolean
}

function Faq(props: Props) {
  const { t } = useTranslation('common')
  const { locale } = useRouter()

  const {
    category,
    faqPage = false,
    withoutMargin = false,
    slug,
    slugEn,
    dataTestId,
    forHomePage = false,
    categoryUrlSlug = '/'
  } = props

  const {
    faqCategories: questions,
    isLoading,
    refetch
  } = useFetchFaqData({
    params: {
      lang: locale,
      forHomePage,
      ...(slug && {
        categoryUrlSlug: slug
      })
    },
    queryKey: [ApiPaths.API_V1_FAQ, categoryUrlSlug]
  })

  const navipay = slugEn === 'navipay-application'

  useEffect(() => {
    refetch()
  }, [slug, refetch])

  return !isLoading ? (
    <Fade triggerOnce>
      <>
        <section
          css={[styles.container, withoutMargin && styles.containerNoMargin]}
          data-test-id={dataTestId}
        >
          {category ? (
            <Title text={category} primary css={styles.title} />
          ) : (
            <Title text={t('faq.title')} />
          )}
          <Grid>
            <div css={styles.accordion}>
              <Accordion
                questions={questions?.items as FaqItem[]}
                wrapWithH2={!!category}
              />
              {!faqPage && <FaqPriceList />}
            </div>
            {faqPage ? (
              navipay ? (
                <TextWithButton
                  text={t('faq.readyForParking')}
                  button={t('faq.buttonNavipay')}
                  link={{ pathname: '/navipay' }}
                />
              ) : (
                <TextWithButton
                  text={t('faq.readyForParking')}
                  button={t('faq.buttonSearch')}
                  link={{ pathname: '/parkings' }}
                />
              )
            ) : (
              <TextWithButton
                text={t('faq.buttonMore')}
                button={t('faq.buttonCheck')}
                link={{ pathname: '/faq' }}
                {...(dataTestId && { dataTestId: `${dataTestId}-button` })}
              />
            )}
          </Grid>
        </section>
      </>
    </Fade>
  ) : null
}

const styles = {
  container: css`
    ${spacingSection};
  `,
  containerNoMargin: css`
    margin-top: 0;

    ${mediaUp('lg')} {
      margin-top: 0;
    }
  `,
  title: css`
    text-align: center;
  `,
  accordion: css`
    ${mediaColumns({ xs: [12, 0], md: [10, 1] })};
    ${spacingSmallSection};
  `
}

export default Faq
