import styled from '@emotion/styled'

import { colors, transitions } from '@/styles/theme'
import { columnWidth } from '@/styles/grid'
import { Text1, Text2Styles } from '@/styles/typography'
import { mediaUp } from '@/styles/media'
import { focusVisible } from '@/styles/mixins'
import ChevronDown from '@/components/icons/ChevronDown'

export const Container = styled.div`
  margin-bottom: 18px;

  ${mediaUp('lg')} {
    margin-bottom: 40px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Head = styled.button`
  ${focusVisible};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: start;
`

export const Text = styled(Text1)`
  margin-inline-end: 16px;

  ${mediaUp('lg')} {
    margin-inline-end: 40px;
  }
`

export const IconWrapper = styled.span`
  border-radius: 50%;
  background-color: ${colors.paleGrey};
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${transitions.base};

  ${Head}:hover & {
    background-color: ${colors.paleHover};
  }

  ${mediaUp('lg')} {
    width: 48px;
    height: 48px;
  }
`

export const Icon = styled(ChevronDown)<{ open: boolean }>`
  transition: transform ${transitions.base};
  stroke: ${colors.darkSkyBlue};
  transform: ${props =>
    props.open ? `translateY(-1px) rotate(180deg)` : `rotate(0deg)`};
  width: 16px;
  height: 16px;
  margin-top: 1px;

  ${mediaUp('lg')} {
    width: 32px;
    height: 32px;
    margin-top: 2px;
    transform: ${props =>
      props.open ? `translateY(-2px) rotate(180deg)` : `rotate(0deg)`};
  }
`

export const Content = styled.div`
  overflow: hidden;
  transition: height ${transitions.base} ease-in-out;

  &,
  p {
    ${Text2Styles};
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    ${Text2Styles};
    color: ${colors.darkSkyBlue};
  }
`

export const ContentInner = styled.div`
  ${columnWidth(1, 'padding-inline-end: ')};

  padding-top: 16px;

  ${mediaUp('md')} {
    padding-top: 15px;
  }

  ${mediaUp('lg')} {
    padding-top: 20px;
  }
`
