import { useState } from 'react'
import html from 'html-react-parser'

import AccordionItem from '@/components/common/AccordionItem'
import { FaqItem, MockedFaqItem } from '@/service'
import { unOrphan } from '@/utils/orphans'

interface Props {
  questions: FaqItem[] | MockedFaqItem[]
  wrapWithH2?: boolean
}

function Accordion(props: Props) {
  const { questions, wrapWithH2 = false } = props
  const [isOpen, setIsOpen] = useState(0)

  const handleAccordion = (resp: number) => {
    setIsOpen(resp)
  }

  return (
    <>
      {questions?.map((el, i) => (
        <AccordionItem
          key={i}
          question={el.title}
          idx={i}
          isOpen={isOpen === i}
          onClick={handleAccordion}
          wrapWithH2={wrapWithH2}
        >
          {html(unOrphan(el.content))}
        </AccordionItem>
      ))}
    </>
  )
}

export default Accordion
