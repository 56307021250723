import { css } from '@emotion/react'

import Button from '@/components/common/Button'

import scrollToAnchor from '@/utils/scrollToAnchor'
import { mediaUp } from '@/styles/media'
import { ButtonStyles } from '@/components/common/Button'

interface Props {
  anchor: string
  text: string
  style?: ButtonStyles
  onClick?(): void
}

function ButtonParking(props: Props) {
  const { anchor, text, style, onClick } = props

  const handleClick = () => {
    scrollToAnchor(anchor)
    onClick && onClick()
  }

  return <Button css={button} text={text} style={style} onClick={handleClick} />
}

const button = css`
  margin-inline-end: 5px;

  ${mediaUp('md')} {
    margin-inline-end: 10px;
  }

  ${mediaUp('lg')} {
    margin-inline-end: 20px;
  }

  &:last-of-type {
    margin-inline-end: 0;
  }
`

export default ButtonParking
